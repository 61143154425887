<template>
  <v-container fluid class="ml-0">
    <v-row
      ><v-col cols="6">
        <v-row class="mt-1 mx-0">
          <v-combobox
            v-model="typeSelected"
            :items="AllTypeNames"
            label="Filter by Type"
            outlined
            dense
            class="mr-1 my-1"
          ></v-combobox>
          <v-combobox
            v-model="toolSelected"
            :items="AllToolNames"
            label="Object Name"
            outlined
            dense
            class="my-1"
          ></v-combobox>
          <v-btn color="primary" class="ma-1" @click="LoadTool">
            <v-icon left> mdi-download</v-icon>
            Load
          </v-btn>
          <v-btn color="primary" class="my-1" @click="CloneTool">
            <v-icon left> mdi-content-duplicate </v-icon>
            Clone
          </v-btn>

          <v-btn color="primary" class="ma-1" @click="SaveTool">
            <v-icon left> mdi-cloud-upload-outline </v-icon>
            Save
          </v-btn>

          <v-btn color="primary" class="my-1" @click="DiscardToolEdits">
            <v-icon left> mdi-close </v-icon>
            Discard
          </v-btn>
          <v-spacer />
        </v-row>

        <v-row class="mb-2 mx-1">
          Load or clone an object, then edit the JSON code below. Verify how the object is
          rendered before saving to the database. Any objects that fail to render properly
          can be loaded here and debugged.
        </v-row>
        <v-jsoneditor v-model="jsonInput" height="80vh" :options="jsonoptions" /> </v-col
      ><v-col cols="6">
        <ToolView
          :authObject="authObject"
          :ToolViewList="injectViewList"
          :InjectedTools="injectedAllTools"
          ForceViewSequence="ObjectEditor"
          searchText=""
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToolView from "@/components/dataview/ToolView";
import ProcessToolData from "@/support/processToolData.js";
import VJsoneditor from "v-jsoneditor/src/index";
import dataloader from "@/api/dataloader.js";

export default {
  components: {
    ToolView,
    VJsoneditor,
  },

  data: () => ({
    toolSelected: "",
    typeSelected: "Tool",
    jsonInput: {
      help: "please load a suitable tool entry to edit",
    },
    jsonoptions: { mode: "code" },
  }),
  props: {
    toolname: { default: "new", type: String }, // full - all groups. none = zero groups, one = just the group containing the tools
  },
  computed: {
    AllTypeNames() {
      var typeList = ["Tool", "Group", "Template"];
      for (const [, value] of Object.entries(this.AllTools)) {
        if (!typeList.includes(value["Type"])) typeList.push(value["Type"]);
      }
      return typeList;
    },
    AllToolNames() {
      var fullList = Object.keys(this.AllTools);
      return fullList.filter(
        (toolname) => this.AllTools[toolname]["Type"] == this.typeSelected
      );
    },
    EditedToolName() {
      var thisTool = this.jsonInput;
      return thisTool.Name;
    },
    searchText() {
      // pick up the search text from the store (usually entered via title bar) and use it to narrow down cards
      return this.$store.getters.getSearchText;
    },
    authObject() {
      return {
        editEnable: this.$store.getters["menu/canEditTools"],
        permissions: this.$store.getters["user/permissions"],
      };
    },

    /*    ToolViewListOld() {
      var instructions = ProcessToolData.GenerateViewInstructions(
        [this.EditedToolName],
        this.InjectedAllTools
      );
      console.log(">" + JSON.stringify(this.EditedToolName));
      console.log(">" + JSON.stringify(instructions));
      if (instructions[this.EditedToolName] !== undefined)
        instructions[this.EditedToolName]._expanded = true;
      return instructions;
    },*/
    AllTools() {
      return this.$store.getters["tools/getTools"];
    },
    RawTools() {
      return this.$store.getters["tools/getRawTools"];
    },
    injectedRawTools() {
      // replaces the jsonTool and creates the new "activeview" list from it
      console.log("inject tool name");
      var rawToolsModified = JSON.parse(JSON.stringify(this.RawTools));
      rawToolsModified[this.jsonInput.Name] = this.jsonInput;
      return rawToolsModified;
    },
    injectedAllTools() {
      return ProcessToolData.ProcessRawTools(this.injectedRawTools);
    },
    injectViewList() {
      // replacs the jsonTool and creates the new "activeview" list from it
      return ProcessToolData.GenerateViewInstructions(
        [this.jsonInput.Name],
        JSON.parse(JSON.stringify(this.injectedAllTools))
      );
    },
  },
  methods: {
    LoadTool() {
      if (this.toolSelected === "") return;
      const thisTool = this.$store.getters["tools/getRawToolByName"](this.toolSelected);
      this.jsonInput = JSON.parse(JSON.stringify(thisTool));
    },
    CloneTool() {
      if (this.toolSelected === "") return;
      const thisTool = this.$store.getters["tools/getRawToolByName"](this.toolSelected);
      this.jsonInput = JSON.parse(JSON.stringify(thisTool));
      this.jsonInput._id = "$new";
    },
    DiscardToolEdits() {
      this.toolSelected = "";
      this.jsonInput = { help: "please load a suitable tool entry to edit" };
    },
    SaveTool() {
      if (this.jsonInput._id === "$new") {
        console.log("Creating new tool");
        if (this.jsonInput.Name === undefined) {
          console.log("Entry lacks name");
          return;
        }
        //  --> SEND REQUEST
        dataloader.sendNewTool(this.jsonInput);
      } else {
        console.log("SAVE " + this.toolSelected);
        // check that _id is present and still the same
        var originalTool = this.AllTools[this.toolSelected];
        if (this.jsonInput._id !== originalTool._id) {
          console.log("ID CHECK FAILED");
          return;
        }
        dataloader.sendToolReplacement(this.jsonInput);
        this.$store.commit("tools/updateSingleTool", this.jsonInput);
        //  --> FIX UPDATE ISSUE
      }
    },
    toggleExpandEvent(msg) {
      console.log("toggle event");
      console.log(JSON.stringify(msg));
    },
  },
};
</script>

<style>
div.jsoneditor-menu {
  background-color: #455a64;
  border: thin solid #263238;
}
div.jsoneditor {
  border: thin solid #263238;
}
a.jsoneditor-poweredBy {
  color: #eceff1;
}
</style>
